

$(document).ready(function(){
  $(".drop-section").click(function()
    {
  if( $(this).hasClass("show"))
        $(this).removeClass("show");
else{
        $(".drop-section").removeClass("show");
        $(this).addClass("show");
    }
         })
    });

