function init(amount = 0, description = '', transaction_id = '',symbol = 'SAR') {
console.log(symbol)
  if(symbol != 'SAR'){
   lastAmount = amount * 100 * 3.75;
  }
  else{
    lastAmount = amount * 100 ;
  }

    Moyasar.init({
        element: '.mysr-form',
        amount: parseFloat(Math.round(lastAmount)),
        // Required
        // Currency of the payment transation
        currency: 'SAR',
        // Required
        // A small description of the current payment process
        description: description,
        transaction_id: transaction_id,
        metadata: {
            'transaction_id': transaction_id
        },
        // Required
        publishable_api_key: 'pk_live_kzypRtSP2HKMVMbiYipxxBfUBYQAnfCUGBRXjd9x',
        // pk_test_QjpZCJ9oouQVLXX7CPuaZbKfFgqET9289zXnKHJt
        // Required
        // This URL is used to redirect the user when payment process has completed
        // Payment can be either a success or a failure, which you need to verify on you system (We will show this in a couple of lines)
        callback_url: 'https://writalk.com/payment',
        // Optional
        // Required payments methods
        // Default: ['creditcard', 'applepay', 'stcpay']
        methods: [
            'creditcard',
        ],

    });


}
