$(document).ready(function(){
    //login toggle
    $(".toggle-password").click(function() {
        $(this).toggleClass("toggle-eye-open");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });

        // scroll
          $(".anchor-section li:eq(0)").click(function() {
              $('html, body').animate({
                  scrollTop: 0,
              }, 1000);
          });
          $(".anchor-section li:eq(1)").click(function() {
              $('html, body').animate({
                  scrollTop: (parseInt($(".section:eq(1)").offset().top) - 70)
              }, 1000);
          });
          $(".anchor-section li:eq(2)").click(function() {
              $('html, body').animate({
                  scrollTop: (parseInt($(".section:eq(2)").offset().top) - 70)
              }, 1000);
          });
          $(".anchor-section li:eq(3)").click(function() {
              $('html, body').animate({
                  scrollTop: (parseInt($(".section:eq(3)").offset().top) - 70)
              }, 1000);
          });

          $('#scroll_down').click(function(){
              $('html, body').animate({
                  scrollTop: (parseInt($(".section:eq(1)").offset().top) - 70)
              }, 1000);
          });

          $(window).scroll(function() {

        if ($(this).scrollTop()+130 >= $('.header-parent-section').offset()?.top) {
            $('.anchor-section li').removeClass('active');
            $('.anchor-section li:eq(0)').addClass('active');
        }
        if ($(this).scrollTop()+130 >= $('.why-wri-tk').offset()?.top) {
            $('.anchor-section li').removeClass('active');
            $('.anchor-section li:eq(1)').addClass('active');
        }
        if ($(this).scrollTop()+130 >= $('.how-does-work-tk').offset()?.top) {
            $('.anchor-section li').removeClass('active');
            $('.anchor-section li:eq(2)').addClass('active');
        }
        if ($(this).scrollTop()+130 >= $('.testimonial-sec-tk').offset()?.top) {
            $('.anchor-section li').removeClass('active');
            $('.anchor-section li:eq(3)').addClass('active');
        }
        else{
          // $('').removeClass('active');
        }



    });

      // add color to label
      $('.flex_froms .form-group').on('click',function(){
        $('.flex_froms .form-group').removeClass('activeLabel');
        $(this).addClass('activeLabel');
      });

    //user profile image upload
      $(document).on('change', '.btn-file :file', function() {
            var input = $(this),
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [label]);
            });

            $('.btn-file :file').on('fileselect', function(event, label) {

                var input = $(this).parents('.input-group').find(':text'),
                    log = label;

                if( input.length ) {
                    input.val(log);
                } else {
                    if( log ) alert(log);
                }

            });
            function readURL(input) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();

                    reader.onload = function (e) {
                        $('#img-upload').attr('src', e.target.result);
                    }

                    reader.readAsDataURL(input.files[0]);
                }
            }

            $("#imgInp").change(function(){
                readURL(this);
            });
      });

      //DTutor
            $(' .Delete_Tutor').click(function () {
              $('.DTutor_wrapper').addClass('panelactive');
              $('body').addClass('bodypanelactive');
            });
            $('.DTutor_osff').click(function () {
              $('.DTutor_wrapper').removeClass('panelactive');
              $('body').removeClass('bodypanelactive');
            });

      //Dialog
          $(' .Delete_Dialog').click(function () {
            $('.Dialog_wrapper').addClass('panelactive');
            $('body').addClass('bodypanelactive');
          });
          $('.Dialog_osff').click(function () {
            $('.Dialog_wrapper').removeClass('panelactive');
            $('body').removeClass('bodypanelactive');
          });

      //notification
        $(' .notification').click(function () {
          $('.notification_wrapper').addClass('panelactive');
          $('body').addClass('bodypanelactive');
        });
        $('.notifi_osff').click(function () {
          $('.notification_wrapper').removeClass('panelactive');
          $('body').removeClass('bodypanelactive');
        });


    //Dashboard base scripts


    $(function () {

    'use strict';

    var aside = $('.side-nav'),
        showAsideBtn = $('.show-side-btn'),
        contents = $('#contents'),
        _window = $(window)

    showAsideBtn.on("click", function () {
      $("#" + $(this).data('show')).toggleClass('show-side-nav');
      contents.toggleClass('margin');
    });

    if (_window.width() <= 767) {
      aside.addClass('show-side-nav');
    }

    _window.on('resize', function () {
      if ($(window).width() > 767) {
        aside.removeClass('show-side-nav');
      }
    });



    // dropdown menu in the side nav
    var slideNavDropdown = $('.side-nav-dropdown');

    // $('.side-nav .categories li').on('click', function () {

    //   var $this = $(this)

    //   $this.toggleClass('opend').siblings().removeClass('opend');

    //   if ($this.hasClass('opend')) {
    //     $this.find('.side-nav-dropdown').slideToggle('fast');
    //     $this.siblings().find('.side-nav-dropdown').slideUp('fast');
    //   } else {
    //     $this.find('.side-nav-dropdown').slideUp('fast');
    //   }
    // });

    // $('.side-nav .close-aside').on('click', function () {
    //   $('#' + $(this).data('close')).addClass('show-side-nav');
    //   contents.removeClass('margin');
    // });


    });


    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
      $('#contents').toggleClass('active');
      $('#navbarSupportedContent').toggleClass('active');
     });

    });


    $(window).scroll(function() {

        if ($(this).scrollTop()+130 >= $('.header-parent-section').offset()?.top) {
            $('.anchor-section li').removeClass('active');
            $('.anchor-section li:eq(0)').addClass('active');
        }
        if ($(this).scrollTop()+130 >= $('.why-wri-tk').offset()?.top) {
            $('.anchor-section li').removeClass('active');
            $('.anchor-section li:eq(1)').addClass('active');
        }
        if ($(this).scrollTop()+130 >= $('.how-does-work-tk').offset()?.top) {
            $('.anchor-section li').removeClass('active');
            $('.anchor-section li:eq(2)').addClass('active');
        }
        if ($(this).scrollTop()+130 >= $('.testimonial-sec-tk').offset()?.top) {
            $('.anchor-section li').removeClass('active');
            $('.anchor-section li:eq(3)').addClass('active');
        }
        else{
          // $('').removeClass('active');
        }



    });


    //confirm password
  //   var password = document.getElementById("password")
  //   , confirm_password = document.getElementById("confirm-password");

  // function validatePassword(){
  //   if(password.value != confirm_password.value) {
  //     confirm_password.setCustomValidity("Passwords Don't Match");
  //   } else {
  //     confirm_password.setCustomValidity('');
  //   }
  // }

  // password.onchange = validatePassword;
  // confirm_password.onkeyup = validatePassword;


    //multiple image upload

    var selDiv = "";
        var storedFiles = [];

        $(document).ready(function() {
            $("#files").on("change", handleFileSelect);

            selDiv = $("#selectedFiles");
            $("#myForm").on("submit", handleForm);

            $("body").on("click", ".selFile", removeFile);
        });

        function handleFileSelect(e) {
            var files = e.target.files;
            var filesArr = Array.prototype.slice.call(files);
            filesArr.forEach(function(f) {

                if(!f.type.match("image.*")) {
                    return;
                }
                storedFiles.push(f);

                var reader = new FileReader();
                reader.onload = function (e) {
                    var html = "<div class='img_flexDiv'><img src=\"" + e.target.result + "\" data-file='' class='selFile''>" +"<div class='abs_relate'>"+ f.name + "</div>";
                    selDiv.append(html);

                }
                reader.readAsDataURL(f);
            });

        }

        function handleForm(e) {
            e.preventDefault();
            var data = new FormData();

            for(var i=0, len=storedFiles.length; i<len; i++) {
                data.append('files', storedFiles[i]);
            }

            var xhr = new XMLHttpRequest();
            xhr.open('POST', 'handler.cfm', true);

            xhr.onload = function(e) {
                if(this.status == 200) {
                    console.log(e.currentTarget.responseText);
                    alert(e.currentTarget.responseText + ' items uploaded.');
                }
            }

            xhr.send(data);
        }

        function removeFile(e) {
            var file = $(this).data("file");
            for(var i=0;i<storedFiles.length;i++) {
                if(storedFiles[i].name === file) {
                    storedFiles.splice(i,1);
                    break;
                }
            }
            $(this).parent().remove();
        }







    // video upload
    // const input = document.getElementById('file-input');
    // const video = document.getElementById('video');
    // const videoSource = document.createElement('source');

    //   input.addEventListener('change', function() {
    //   const files = this.files || [];

    //   if (!files.length) return;

    //   const reader = new FileReader();

    //   reader.onload = function (e) {
    //     videoSource.setAttribute('src', e.target.result);
    //     video.appendChild(videoSource);
    //     video.load();
    //     // video.play();
    //   };

    //   reader.onprogress = function (e) {
    //     console.log('progress: ', Math.round((e.loaded * 100) / e.total));
    //   };

    //   reader.readAsDataURL(files[0]);
    // });






